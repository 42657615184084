// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-chatmail-tsx": () => import("./../../../src/pages/work/chatmail.tsx" /* webpackChunkName: "component---src-pages-work-chatmail-tsx" */),
  "component---src-pages-work-communo-tsx": () => import("./../../../src/pages/work/communo.tsx" /* webpackChunkName: "component---src-pages-work-communo-tsx" */),
  "component---src-pages-work-imagewear-tsx": () => import("./../../../src/pages/work/imagewear.tsx" /* webpackChunkName: "component---src-pages-work-imagewear-tsx" */),
  "component---src-pages-work-marris-miller-tsx": () => import("./../../../src/pages/work/marris-miller.tsx" /* webpackChunkName: "component---src-pages-work-marris-miller-tsx" */),
  "component---src-pages-work-money-mentors-tsx": () => import("./../../../src/pages/work/money-mentors.tsx" /* webpackChunkName: "component---src-pages-work-money-mentors-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

